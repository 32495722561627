// src/index.js

// Purpose: The entry point of the React application. It renders the App component into the root DOM node.

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

ReactDOM.render(<App />, document.getElementById('root'));


// Explanation:

// 1. Imports:
// React and ReactDOM are imported to use JSX and render components.
// App is the main component of the application.
// Bootstrap CSS and custom CSS are imported for styling.

// 2. ReactDOM.render:
// Renders the App component into the HTML element with the id root.
