// src/components/Footer.js

// Purpose: Displays the footer at the bottom of the page.

import React from 'react';

function Footer() {
  return (
    <footer className="bg-primary text-white text-center py-3 mt-auto">
      &copy; Edvinci Innovation Labs Private Limited. All rights reserved.
    </footer>
  );
}

export default Footer;

// Explanation:

// Footer Component:
// Uses Bootstrap classes for styling.
// Displays the current year dynamically using new Date().getFullYear().
