// src/components/Products.js

// Purpose: Displays the content for the Products page.

import React from 'react';
import { Row, Col } from 'react-bootstrap';

function Products() {
  return (
    <>
      <Row>
        <Col>
          <h3 className="text-secondary mt-4">Ed Vinci Speed Maths App:</h3>
          <p className="text-justify">
            Learning maths can now be fun!!!.  Ed Vinci's speed maths application is designed to boost your mental agility and problem-solving skills. Developed by world-class professionals, the app uses simple and highly effective techniques to improve your calculation speed and adopt an analytical approach to problem solving. With unlimited practice session, the app can be a perfect partner for cracking your competitive exams.
          </p>
          <h3 className="text-secondary mt-4">Ed Vinci Super Series App:</h3>
          <p className="text-justify">
            Ed Vinci Super Series app is a true gift for all competitive examination aspirants. With comprehensive study materials, preparation tips, and revision tests, students who have little time to prepare can quickly revise and practice for their exams. The Ed Vinci course materials are prepared by expert trainers, with years of experience in the field, and is designed to help students achieve a guaranteed minimum level of performance.
          </p>
          <h3 className="text-secondary mt-4">Ed Vinci 5W Series:</h3>
          <p className="text-justify">
            The greatest inventions were brought about by people who had the attributes of kids. Ed Vinci’s 5W series helps young minds to rekindle this sense of wonder and the ability to constantly question anything and everything around them. The series throws light on several topics that appear mundane but are in fact fundamental to our lives. So get ready to bring out your curiosity hats and rekindle the child in you.
          </p>
        </Col>
      </Row>
    </>
  );
}

export default Products;

// Explanation:

// Products Component:
// Uses headings (h1, h3) and paragraphs to explain the product features.
// An ordered list (<ol>) is used to present the steps in "How it Works".
