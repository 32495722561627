// src/components/ContactUs.js

// Purpose: Displays the content for the Contact Us page.

import React from 'react';
import { Row, Col } from 'react-bootstrap';

function ContactUs() {
  return (
    <>
      <Row>
        <Col md={12} lg={12}>
          <h1 className="text-primary mb-4">Questions?</h1>
          <h3 className="mb-4">We're here to help</h3>
          <p className="mb-4">Reach out to us:</p>
          <p className="mb-2">
            <strong>Name:</strong> Murali Krishna
          </p>
          <p className="mb-2">
            <strong>Mobile:</strong>{' '}
            <a href="tel:+91-9980754664" className="text-decoration-none">
              +91-9980754664
            </a>
          </p>
          <p className="mb-2">
            <strong>Email:</strong>{' '}
            <a href="mailto:murali@heyperi.ai" className="text-decoration-none">
              murali@heyperi.ai
            </a>
          </p>
          <p className="mb-2">
            <strong>LinkedIn:</strong>{' '}
            <a
              href="https://www.linkedin.com/in/kmkrishna"
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoration-none"
            >
              Murali's LinkedIn Profile
            </a>
          </p>
          <p className="mb-2">
            <strong>Address:</strong> #G2, Teja Villa, 3rd Main, 1st Cross, Ashwini Layout, Ejipura, Bengaluru - 560047, India.
          </p>
        </Col>
      </Row>
    </>
  );
}

export default ContactUs;

// Explanation:

// - Added 'mb-4' class to headings and 'mb-2' to contact details for consistent spacing.
// - Wrapped the contact information in a Col with specified widths for better alignment on larger screens.
// - Used 'text-decoration-none' class on links to remove underlines, enhancing visual appeal.
// - Ensured the content is responsive by using Bootstrap's grid system.
