// src/components/Navbar.js

// Purpose: Displays the navigation bar with links to different pages.

import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

function NavbarComponent() {
  return (
    <Navbar bg="primary" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand href="/" className="text-white fw-bold">
          Edvinci
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <LinkContainer to="/">
              <Nav.Link className="text-white">Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/about">
              <Nav.Link className="text-white">About Us</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/products">
              <Nav.Link className="text-white">Products</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/contact">
              <Nav.Link className="text-white">Contact Us</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarComponent;

// Explanation:

// 1. Imported 'Container' from 'react-bootstrap' and wrapped the navbar content inside it.
//    This adds horizontal padding and centers the navbar content, preventing the brand name from being too close to the left edge.

// 2. Ensured the 'Navbar.Brand' remains the same, linking to the home page.
// 3. Added 'className="text-white"' to 'Nav.Link' components for consistent text color.
