// src/App.js

// Purpose: The main component that sets up routing and includes the navbar and footer.

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavbarComponent from './components/Navbar';
import Footer from './components/Footer';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import Products from './components/Products';
import ContactUs from './components/ContactUs';
import { Container } from 'react-bootstrap';

function App() {
  return (
    <Router>
      {/* Add the flex container with Bootstrap classes */}
      <div className="d-flex flex-column min-vh-100">
        <NavbarComponent />
        {/* Main content area with flex-grow-1 to fill available space */}
        <Container className="mt-4 flex-grow-1">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/products" element={<Products />} />
            <Route path="/contact" element={<ContactUs />} />
          </Routes>
        </Container>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

// Explanation:

// 1. Imports:
// React and necessary components for routing (BrowserRouter, Route, Switch).
// Custom components: Navbar, Footer, and the pages.

// 2. App Component:
// Wrapped with Router to enable routing.
// Includes NavbarComponent at the top and Footer at the bottom.
// The Switch component renders the first child <Route> that matches the location.
// Defines routes for each page:
// / for Home
// /about for AboutUs
// /products for Products
// /contact for ContactUs
