// src/components/Home.js

// Purpose: Displays the content for the Home page.

import React from 'react';
import { Row, Col } from 'react-bootstrap';

function Home() {
  return (
    <>
      <Row>
        <Col>
          <h1 className="text-primary mb-4">
            Welcome to Edvinci Innovation Labs – your provider of educational products and solutions!
          </h1>
          <p className="text-justify mb-4">
            Ed Vinci is a brainchild of IITians with a deep passion for the education sector. We offer a wide range of self-paced learning products and solutions that cater to ‘learners’ across all age groups.
          </p>
          <p className="text-justify mb-4">
            Ed Vinci believes in the power of technology to bridge gaps and break barriers.  We constantly strive to harness the power of internet, social media, and other relevant modern technologies to not just educate but also inspire people across all demographics.
          </p>
          <h3 className="text-secondary mt-4">
            Ed Vinci – Let’s educate and inspire young minds!
          </h3>
        </Col>
      </Row>
    </>
  );
}

export default Home;

// Explanation:

// - Added 'mb-4' class to headings and paragraphs for consistent spacing.
// - Used 'mt-4' class on the last heading to add top margin.
// - Kept 'text-justify' class on paragraphs for justified text alignment.
// - Ensured that the headings and text are visually appealing and have appropriate spacing.
