// src/components/AboutUs.js

// Purpose: Displays the content for the About Us page.

import React from 'react';
import { Row, Col } from 'react-bootstrap';

function AboutUs() {
  return (
    <>
      <Row>
        <Col>
          <h1 className="text-primary mb-4">About Edvinci Innovation Labs</h1>
          <p className="text-justify mb-4">
            Ed Vinci is an India- based provider of educational products and solutions. We help “young minds”, to unleash their limitless potential, through products that drive inquisitiveness and creativity.
          </p>
          <p className="text-justify mb-4">
            Our vision is to educate and inspire young minds through high-quality and innovative educational products and solutions.
          </p>
          <p className="text-justify mb-4">
            Ed Vinci is dedicated to constantly providing customers with very high quality educational products and solutions. To accomplish this we would 
            <ul className="list-unstyled">
              <li className="mb-2">
                <i className="bi bi-check-circle text-success me-2"></i>
                Constantly work towards understanding the needs and requirements of our customers.
              </li>
              <li className="mb-2">
                <i className="bi bi-check-circle text-success me-2"></i>
                Deploy the right mix of people to analyse, conceptualize, productize, and commercialize our products and solutions.
              </li>
              <li className="mb-2">
                <i className="bi bi-check-circle text-success me-2"></i>
                Utilize the latest technology available to provide innovative and exciting educational products and solutions.
              </li>
              <li className="mb-2">
                <i className="bi bi-check-circle text-success me-2"></i>
                Appreciate the trust placed on us by customers and provide them with best-in-class solutions and services.
              </li>
            </ul>
          </p>
        </Col>
      </Row>
    </>
  );
}

export default AboutUs;

// Explanation:

// - Added 'mb-4' class to paragraphs for consistent spacing.
// - Used 'text-justify' class on paragraphs for justified text alignment.
// - Ensured the content is wrapped in 'Row' and 'Col' components for responsiveness.
